.identitySection {
    display: flex;
    align-items: center;
    justify-content: center;
}
.identityContainer {
    display: flex;
    width: 75%;
    height: 100%;
    flex-direction: column;
}
.identityTitle {
    font-size: 32pt;
    color: #004435;
    font-family: "Noto Sans KR";
    font-weight: 900;
    margin-top: 100px;
    margin-bottom: 0;
}
.contentsBox {
    display: flex;
    width: 100%;
    height: 100%;
}
.identity {
    width: 100%;
    height: 100%;
}
.contents {
    display: none;
}

@media (max-width: 1460px) {
    .identityContainer {
        width: 100%;
    }
    .identityTitle {
        margin-left: 70px;
        font-size: 30pt;
    }
}

@media (max-width: 1250px) {
    .contentsBox {
        flex-wrap: wrap;
        width: 85%;
        margin: auto;
    }
    #identityImg {
        display: none;
    }
    .contents {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin-top: 50px;
    }
    .contentsTitleBox {
        display: flex;
        align-items: baseline;
    }
    .contentsTitle {
        font-size: 25pt;
        font-weight: 800;
        color: #004435;
        margin: 0;
    }
    .contentsTitleBox span {
        font-size: 18pt;
        color: #004435;
        margin-left: 20px;
        font-weight: 800;
    }
    .contentsDescBox {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
    }
    .contentsDesc {
        color: #000;
        font-size: 15pt;
        font-weight: 600;
    }
}

@media (max-width: 1070px) {
    .contentsBox {
        flex-direction: column;
    }
    .contents {
        width: 80%;
        margin: 20px auto 20px auto;
    }
}

@media (max-width: 700px) {
    .identityTitle {
        margin-left: 30px;
        font-size: 18pt;
    }
    .contentsTitle {
        font-size: 15pt;
    }
    .contentsTitleBox span {
        font-size: 12pt;
        margin-left: 10px;
    }
    .contents {
        width: 100%;
    }
    .contentsDesc {
        font-size: 12pt;
    }
}

@media (max-width: 500px) {
    .contentsDesc {
        font-size: 10pt;
    }
}
* {
    word-break: keep-all;
    font-family: "Noto Sans KR";
}
::-webkit-scrollbar {
    display: none;
}
body {
    background-color: #fff;
}
a {
    text-decoration: none;
}
main {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1;
}
.sections {
    position: relative;
    width: 100vw;
    min-height: 100vh;
}
.backgrounds {
    position: absolute;
    /* top: 80px; */
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -9;
}
.backgrounds::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -5;
    background-color: rgba(0, 0, 0, 0.2);
}

#introBg .slick-slider, #introBg .slick-list, #introBg .slick-slide {
    height: 100vh;
    z-index: -99;
}

/********************************************

                    header

***********************************************/
#top-navbar {
    position: fixed;
    display: flex;
    align-items: center;
    height: 80px;
    width: 100%;
    background-color: #fff;
    border-bottom: 2px solid #004435;
    top: 0;
    z-index:99;
    transition: all 0.3s ease;
}
.navbarContainer {
    width: 80%;
    max-width: 1600px;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.navbar-logo {
    display: flex;
    width: 15%;
    height: 100%;
    align-items: center;
}
.navbar-logo a {
    display: flex;
    height: 100%;
    text-align: center;
}
#logo {
    height: 100%;
}

.menu-box {
    display: flex;
    width: 80%;
    height: 100%;
    justify-content: space-between;
}
.menu-list-box {
    display: flex;
    width: 62%;
    height: 100%;
    justify-content: end;
    align-items: center;
}
.menu-item {
    padding: 8px 15px;
    margin: 0 30px;
    text-decoration: none;
    color: #004435;
    font-family: "Noto Sans KR";
    border: 2px solid #fff;
    font-weight: 600;
    font-size: 15pt;
    transition: all 0.3s ease;
}
.menu-item:hover {
    border: 2px solid #004435;
}
.menu-item:last-child {
    margin-right: 50px;
}
.link-list-box {
    display: flex;
    width: 33%;
    height: 100%;
    align-items: center;
}
.link-list {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    align-items: center;
}
.link-item {
    text-decoration: none;
    padding: 8px 15px;
    margin: none;
    color: #fff;
    background-color: #004435;
    border: 2px solid #004435;
    font-family: "Noto Sans KR";
    font-weight: 600;
    font-size: 15pt;
    transition: all 0.3s ease;
}
.link-item:last-child {
    animation-name: flicker;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
.link-item:hover {
    background-color: #fff;
    color: #004435;
}

@keyframes flicker {
    from {
        background-color: #fff;
        color: #004435;
    }
    to {
        background-color: #004435;
        color: #fff;
    }
}

@media (max-width: 1680px) {
    .navbarContainer {
        width: 90%;
    }
}

@media (max-width: 1460px) {
    .navbar-logo {
        width: auto;
        margin-left: 40px;
    }
    .menu-box {
        height: fit-content;
        justify-content: flex-end;
    }

    .burger__menu,
    .x__menu {
        display: block;
        cursor: pointer;
        margin-right: 40px;
    }

    .burger__menu > div,
    .x__menu > div {
        width: 25px;
        height: 3px;
        background-color: #004435;
        margin: 5px;
        transition: all 0.3s ease;
    }

    .x__menu > .burger_line1 {
        transform: rotate(-45deg) translate(-5px, 6px);
    }

    .x__menu > .burger_line2 {
        opacity: 0;
    }

    .x__menu > .burger_line3 {
        transform: rotate(45deg) translate(-5px, -6px);
    }

    .menu-list-box, .link-list-box {
        width: auto;
        height: 100%;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .menu-list-visible {
        width: 100%;
        height: 55%;
        position: fixed;
        display: block;
        opacity: 1;
        right: 0;
        top: 80px;
        background-color: rgba(0, 0, 0, 0.7);
        transition: opacity 0.3s ease;
    }
    .link-list-visible {
        width: 100%;
        height: 45%;
        position: fixed;
        display: block;
        opacity: 1;
        right: 0;
        top: 55%;
        transform: translateY(80px);
        background-color: rgba(0, 0, 0, 0.7);
        transition: opacity 0.3s ease;
    }

    .menu-list, .link-list {
        display: flex;
        position: relative;
        flex-direction: column;
    }
    .menu-list {
        top: 30px;
    }
    .menu-item {
        border: none;
        text-align: center;
        padding: 15px;
        color: #fff;
    }
    .menu-item:last-child, .link-item:last-child {
        margin-right: inherit;
    }
    .menu-item:hover {
        border: none;
        background-color: #fff;
        color: #004435;
    }
    
    .link-list {
        align-items: normal;
        justify-content: start;
    }
    .link-item {
        padding: 15px;
        text-align: center;
        margin: 0;
        margin-bottom: 20px;
    }

    .menu-list-hidden > .menu-list,
    .link-list-hidden > .link-list {
        display: none;
    }

    .menu-item {
        margin: 15px 0;
    }
}

@media (max-width: 600px) {
    #top-navbar {
        height: 60px;
    }
    .menu-list-visible {
        top: 60px;
    }
    .link-list-visible {
        transform: translateY(60px);
    }
}


/********************************************

                    side menu

***********************************************/
#side-nav {
    position: fixed;
    width: 100px;
    top: 50%;
    right: 11%;
    transform: translateY(-40%);
    z-index: 99;
}
.sideNavBox {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.side-item {
    background-color: #fff;
    color: #004435;
    border: 2px solid #004435;
    border-bottom: none;
    text-align: center;
    width: 100%;
    padding: 10px 10px;
    font-size: 11pt;
    font-family: "Noto Sans KR";
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}
.side-item:last-child {
    border-bottom: 2px solid #004435;
}
.side-item:hover, .side-item-focused {
    background-color: #004435;
    color: #fff;
}

@media (max-width: 1460px) {
    #side-nav {
        width: 100%;
        top: 80px;
        right: 0;
        transform: translateY(0);
        z-index: 9;
    }
    .sideNavBox {
        flex-direction: row;
    }
    .side-item {
        border-bottom: 2px solid #004435;
    }
}

@media (max-width: 600px) {
    #side-nav {
        top: 60px;
    }
}

/********************************************

                    Edit page

***********************************************/
.editSection {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.editContainer {
    width: 80%;
    min-height: 90%;
    margin: auto;
    margin-top: 100px;
    border: 1px solid #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
}
.titleBox {
    width: 95%;
    display: flex;
    margin: 10px 10px 10px 10px;
    justify-content: center;
}
.inputTitle {
    border: 0;
    box-shadow: 0 0 2px 1px gray;
    font-size: 20px;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}
.linkBox {
    width: 95%;
    display: flex;
    margin: 10px 10px 10px 10px;
    justify-content: center;
}
.inputLink {
    border: 0;
    box-shadow: 0 0 2px 1px gray;
    font-size: 20px;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}

.thumbnailBox {
    width: 100%;
    display: flex;
    justify-content: start;
    margin-bottom: 20px;
    margin-left: 5%;
}
.newsThumbnail {
    width: 95% !important;
    display: flex;
    margin: 0 10px 10px 10px !important;
    justify-content: center !important;
}
.thumbnailBox label {
    margin-right: 20px;
    font-size: 16px;
}

.imgBox {
    width: 100%;
    display: flex;
    justify-content: start;
    margin-bottom: 20px;
    margin-left: 5%;
}
.imgBox label {
    margin-right: 20px;
    font-size: 16px;
}

.editorBox {
    height: 100%;
    width: 95%;
}

.ck.ck-editor, .ck.ck-editor__main, .ck.ck-content {
    min-height: 500px;
}

.ck.ck-content img {
    width: 300px;
    margin: 0 auto;
}

.buttonBox {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.editBtns {
    padding: 5px 15px;
    font-size: 12pt;
    font-weight: 600;
    margin: 10px 20px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.btnEditSubmit {
    border: 3px solid #004435;
    color: #fff;
    background-color: #004435;
}
.btnEditSubmit:hover {
    color: #004435;
    background-color: #fff;
}
.btnEditCancel {
    border: 3px solid #ff5555;
    color: #ff5555;
    background-color: #fff;
}
.btnEditCancel:hover {
    color: #fff;
    background-color: #ff5555;
}


@media (max-width: 1260px) {
    .editContainer {
        width: 90%;
    }
}

@media (max-width: 960px) {
    .editContainer {
        width: 100%;
    }
}



/********************************************

                    footer

***********************************************/
.footer {
    display: flex;
    width: 100%;
    height: 70px;
    background-color: #fff;
    border-top: 2px solid #004435;
}
.footer-container {
    display: flex;
    width: 90%;
    max-width: 1600px;
    height: 100%;
    margin: auto;
    justify-content: space-between;
}
.footer p {
    margin: 0;
    font-family: "Noto Sans KR";
}
.footer-left {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.footer-name-box, .footer-copy-box {
    display: flex;
    width: 220px;
    height: 25px;
    align-items: center;
}
.footer-name, .footer-copy {
    font-size: 11pt;
    font-weight: 600;
}
.footer-center {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
    justify-content: center;
}
.footer-addr, .footer-phone {
    font-size: 11pt;
    font-weight: 600;
    height: 25px;
    line-height: 25px;
    padding-left: 80px;
}
.footer-phone span {
    padding-left: 50px;
}
.footer-sns {
    display: flex;
    width: 200px;
    height: 100%;
    justify-content: space-around;
    align-items: center;
}
.footer-sns a {
    opacity: 0.7;
    transition: all 0.2s ease;
}
.footer-sns a:hover {
    opacity: 1;
}
.footer-sns img {
    width: 40px;
}
.footer-logo-box {
    display: flex;
    width: 250px;
    justify-content: center;
    align-items: center;
}
.footer-logo {
    height: 100%;
}

@media (max-width: 1460px) {
    .footer {
        flex-direction: column;
        height: fit-content;
    }
    .footer-container {
        flex-direction: column;
        height: fit-content;
    }
    .footer-logo {
        height: 100px;
    }
    .footer div {
        width: 100%;
    }
    .footer-left div {
        padding: 5px 0;
        justify-content: center;
    }
    .footer-name-box {
        margin-top: 10px;
    }
    .footer-name, .footer-copy {
        font-size: 12pt;
    }
    .footer-addr, .footer-phone {
        padding: 5px 0;
        text-align: center;
        font-size: 12pt;
    }
    .footer-phone {
        height: fit-content;
        display: flex;
        flex-direction: column;
    }
    .footer-phone span {
        padding: 5px 0;
    }
    .footer-sns {
        justify-content: center;
    }
    .footer-sns a {
        padding: 5px 10px;
    }
}

@media (max-width: 600px) {
    .footer-addr, .footer-phone {
        font-size: 10pt;
    }
    .footer-sns a {
        padding: 3px 20px;
    }
    .footer-sns img {
        width: 30px;
    }
    .footer-logo {
        height: 60px;
    }
}


/********************************************

                comming soon

***********************************************/
.commingBgBox {
    display: flex;
    align-items: end;
    width: 40%;
    left: 50%;
    transform: translateX(-50%);
}
.commingBgBox::after {
    background-color: rgba(0, 0, 0, 0.3);
}
.commingBg {
    width: 100%;
    height: auto;
    margin: 0 auto;
    z-index: -999;
}
.commingTitle {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 30pt;
    font-weight: 900;
    color: white;
    width: 100%;
    text-align: center;
}

@media (max-width: 1460px) {
    .commingBgBox {
        width: 45%;
    }
    .commingTitle {
        font-size: 25pt;
    }
}

@media (max-width: 1150px) {
    .commingBgBox {
        width: 65%;
    }
}

@media (max-width: 900px) {
    .commingTitle {
        font-size: 18pt;
    }
}

@media (max-width: 600px) {
    .commingBgBox {
        width: 100%;
    }
    .commingTitle {
        top: 30%;
    }
}

.introSection {
    display: flex;
    align-items: center;
    justify-content: center;
}
.introBgBox {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.introBgImg {
    width: 100%;
    z-index: -999;
}
.introContainer {
    display: flex;
    flex-direction: column;
    width: 70%;
}
.introTitle {
    font-size: 30pt;
    color: #fff;
    margin-top: 100px;
    font-family: "Noto Sans KR";
    font-weight: 800;
}
.contents {
    font-size: 18pt;
    color: #fff;
    font-family: "Noto Sans KR";
    font-weight: 400;
    margin: 12px 0;
}

@media (max-width: 1460px) {
    .introBgImg {
        width: auto;
        height: 100%;
    }
    .introContainer {
        width: 85%;
    }
    .introTitle {
        font-size: 20pt;
    }
    .contents {
        font-size: 14pt;
    }
}

@media (max-width: 500px) {
    .introTitle {
        font-size: 15pt;
    }
    .contents {
        font-size: 11pt;
        margin: 0;
        line-height: 19pt;
    }
    #resizeBox {
        align-items: center !important;
    }
    #resizeImg {
        width: 100% !important;
        height: 100% !important;
    }
}
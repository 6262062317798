.titleBox {
    margin-top: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #004435;
}
.titleBox p {
    margin-top: 20px;
    margin-bottom: 30px;
}
.title {
    font-size: 25pt;
    font-weight: 800;
    margin-bottom: 0;
    font-family: "Noto Sans KR";
}
.date {
    width: 80%;
    text-align: end;
    font-size: 15pt;
    font-weight: 600;
    color: #555;
    margin-bottom: 20px;
    font-family: "Noto Sans KR";
}

.contentsBox {
    width: 60%;
    margin: auto;
    padding: 20px 0;
}
.imgBox {
   width: 100%; 
   text-align: center;
}

.img {
    width: 100%;
}

.linkBox {
    width: 60%;
    margin: auto;
}

.contentsBox div {
    text-align: center;
}
.contents {
    text-align: start !important;
    font-family: "Noto Sans KR";
}
.contents figure {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btnBox {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnBox button {
    margin: 0 20px;
    font-size: 12pt;
    padding: 5px 15px;
    font-weight: 600;
    cursor: pointer;
}

.modify {
    color: #fff;
    border: 3px solid #004435;
    background-color: #004435;
    transition: all 0.3s ease;
}
.modify:hover {
    color: #004435;
    background-color: #fff;
}

.delete {
    color: #ff5555;
    background-color: #fff;
    border: 3px solid #ff5555;
    transition: all 0.3s ease;
}
.delete:hover {
    color: #fff;
    background-color: #ff5555;
}

@media (max-width: 1050px) {
    .titleBox p {
        margin-top: 20px;
    }
    .title {
        font-size: 15pt;
        font-weight: 800;
        margin-bottom: 0;
    }
    .date {
        width: 80%;
        text-align: end;
        font-size: 12pt;
        font-weight: 600;
        color: #555;
        margin-bottom: 20px;
    }
    .contentsBox, .linkBox {
        width: 80%;
    }
}

@media (max-width: 780px) {
    .titleBox {
        width: 90%;
        margin: auto;
        margin-top: 90px;
    }
    .titleBox p {
        margin-top: 0;
    }
    .title {
        font-size: 15pt;
        font-weight: 800;
        margin-bottom: 0;
    }
    .date {
        width: 80%;
        text-align: end;
        font-size: 12pt;
        font-weight: 600;
        color: #555;
        margin-bottom: 10px;
    }
    .contentsBox, .linkBox {
        width: 90%;
    }
    .contents p {
        margin: 0;
    }
    .contents p:first-child {
        margin-top: 10px;
    }
}

@media (max-width: 700px) {
    iframe {
        width: 400px;
        height: 260px;
    }
}

@media (max-width: 500px) {
    iframe {
        width: 300px;
        height: 200px;
    }
}

@media (max-width: 300px) {
    iframe {
        width: 220px;
        height: 140px;
    }
    .title {
        font-size: 11pt;
        font-weight: 800;
        margin-bottom: 0;
    }
    .date {
        width: 100%;
        text-align: end;
        font-size: 9pt;
        font-weight: 600;
        color: #555;
        margin-bottom: 10px;
    }
    .contents p {
        font-size: 9pt;
    }
    .linkBox {
        font-size: 9pt;
    }
}

.articleCard {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 25%;
    margin: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
}
.imgBox {
    width: 100%;
    height: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-bottom: 1px solid #000;
}
.cardImg {
    height: 100%;
    transition: all 0.2s ease;
}
.contentBox {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: column;
    padding: 5px;
}
.titleBox {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 15pt;
    font-weight: bold;
}
.title {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;   
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
    font-family: "Noto Sans KR";
}

.articleCard:hover .cardImg {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}



.bgrt {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
}
.bgrt::after {
    content: '';
    position: absolute;
    right: -2px;
    top: -2px;
    width: 20px;
    height: 20px;
    background-color: #004435;
    z-index: -9;
}
.bglt {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
}
.bglt::after {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    width: 20px;
    height: 20px;
    background-color: #004435;
    z-index: -9;
}
.bgrb {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
}
.bgrb::after {
    content: '';
    position: absolute;
    right: -2px;
    bottom: -2px;
    width: 20px;
    height: 20px;
    background-color: #004435;
    z-index: -9;
}
.bglb {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20px;
    height: 20px;
}
.bglb::after {
    content: '';
    position: absolute;
    left: -2px;
    bottom: -2px;
    width: 20px;
    height: 20px;
    background-color: #004435;
    z-index: -9;
}

@media (max-width: 910px) {
    .articleCard {
        width: 40%;
        margin: 8px auto;
    }
    .articleCard:last-child {
        margin: 5px 5%;
    }
}

@media (max-width: 600px) {
    .articleCard {
        width: 100%;
        margin: 5px 0;
    }
    .articleCard:last-child {
        margin: 5px 0;
    }
}
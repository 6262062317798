.introBgBox {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    text-align: center;
    display: flex;
    justify-content: center;
}

.introBgImg {
    height: 100%;
    width: 100%;
    z-index: -999;
}

.introContainer {
    display: flex;
    align-items: center;
    width: 70%;
    height: 100%;
    margin: auto;
}
.introMsg {
    margin-top: 30%;
}
.introMsgContent {
    color: #fff;
    font-size: 25pt;
    font-weight: 600;
    animation: fadein 2s ease;
}
.introMsgContent:first-child {
    font-size: 23pt;
    font-family: "Noto Sans KR";
    font-weight: 400;
}
.introMsgContent:last-child {
    font-size: 26pt;
    font-family: "Noto Sans KR";
    font-weight: 800;
}

@keyframes fadein {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        transform: none;
    }
}

@media (max-width: 1460px) {
    .introContainer {
        height: 100vh;
    }
    .introBgImg {
        width: auto;
    }
    .introMsg {
        padding: 0;
        margin: auto;
    }
    .introMsgContent {
        font-size: 18pt;
    }
}

@media (max-width: 600px) {
    .introMsgContent {
        font-size: 15pt !important;
    }
    #resizeBox {
        align-items: center !important;
    }
    #resizeImg {
        width: 100% !important;
        height: 100% !important;
    }
}

@media (max-width: 300px) {
    .introMsgContent {
        font-size: 12pt !important;
    }
}
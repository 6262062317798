.magazineSection {
    min-height: 70vh;
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 100%;
    margin: auto;
}

.titleBox {
    position: relative;
    margin: auto;
    margin-top: 60px;
    width: 75%;
    text-align: center;
}

.title {
    font-size: 30pt;
    color: #004435;
    font-weight: 800;
    margin-bottom: 0;
}

.contentsList {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    flex-wrap: wrap;
}

.imgBox {
    position: relative;
    display: flex;
    width: 450px;
    height: 600px;
    margin: 10px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.imgBox a::after {
    content: '2023 도고도감';
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    opacity: 0;
    color: #fff;
    font-size: 18pt;
    font-weight: 800;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease;
}
#first::after {
    content: '2022 도고도감' !important;
}
.imgBox:hover a::after {
    opacity: 1;
}
.imgBox a {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}
.cardThumbnail {
    width: 100%;
    transition: all 0.2s ease;
}
.imgBox:hover .cardThumbnail {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

@media (max-width: 600px) {
    .container {
        width: 90%;
    }
    .imgBox {
        height: 450px;
    }
}
.spaceCard {
    position: relative;
    display: flex;
    width: 100%;
    height: 750px;
    margin-bottom: 20px;
}
.spaceImgBox {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -99;
}
.imgBox {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    z-index: -99;
}
.spaceImgBox::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(0, 0, 0, 0.6);
    z-index: -9;
    border-radius: 10px;
    opacity: 0;
    transition: all 0.3s ease;
}
.spaceCard:hover .spaceImgBox::after,
.spaceCard:hover .contentsBox {
    opacity: 1;
}
.carouselItem {
    position: relative;
    display: flex !important;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.spaceImg {
    width: 100%;
    height: 100%;
    margin: auto;
}

.spaceContentBox {
    display: flex;
    width: 100%;
    height: 80%;
    margin: auto;
    justify-content: center;
}
.contentsBox {
    opacity: 0;
    color: #fff;
    transition: all 0.3s ease;
}
.outerBox {
    display: flex;
    flex-direction: column;
    width: 92%;
    height: 50%;
}
.nameBox {
    margin-bottom: 30px;
}
.desc {
    font-size: 15pt;
    font-weight: 500;
    font-family: "Noto Sans KR";
    color: #004435;
    display: none;
}
.name {
    font-size: 25pt;
    font-weight: 800;
    font-family: "Noto Sans KR";
    color: #fff;
    padding: 10px 20px;
    background-color: #004435;
}
.addr {
    font-size: 15pt;
    font-weight: 600;
}
.contents {
    font-size: 15pt;
    font-weight: 600;
}
.right {
    text-align: end;
}

.spaceLeftBg {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 100, 53, 0.2); */
    z-index: -99;
}

.spaceRightBg {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -99;
}
@media (max-width: 1460px) {
    .spaceCard {
        height: 600px;
    }
}

@media (max-width: 1200px) {
    .spaceCard {
        height: 500px;
    }
}

@media (max-width: 900px) {
    .imgBox {
        border-radius: 0;
    }
    .spaceImgBox::after {
        border-radius: 0;
    }
    .name {
        font-size: 18pt;
    }
    .addr {
        font-size: 13pt;
        font-weight: 600;
    }
    .contents {
        font-size: 13pt;
        font-weight: 600;
    }
}

@media (max-width: 750px) {
    .spaceCard {
        height: 400px;
    }
}

@media (max-width: 500px) {
    .spaceCard {
        height: 250px;
    }
    .imgBox {
        border-radius: 0;
    }
    .spaceImgBox::after {
        border-radius: 0;
    }
    .name {
        font-size: 13pt;
    }
    .addr {
        font-size: 11pt;
        font-weight: 600;
    }
    .contents {
        font-size: 11pt;
        font-weight: 600;
    }
}
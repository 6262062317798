.container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 100%;
    margin: auto;
}
.titleBox {
    position: relative;
    margin: auto;
    margin-top: 60px;
    width: 75%;
    text-align: center;
}
.title {
    font-size: 30pt;
    color: #004435;
    font-weight: 800;
    margin-bottom: 0;
}
.contentsList {
    display: flex;
    margin-top: 50px;
}

.editBtn {
    position: absolute;
    right: 50px;
    top: 120px;
    width: 100px;
    height: 40px;
    font-size: 14pt;
    font-weight: 600;
    color: #004435;
    border: 3px solid #004435;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
}
.editBtn:hover {
    background-color: #004435;
    color: #fff;
}

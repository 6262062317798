.localCard {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 400px;
    margin-bottom: 30px;
}
.nameBox {
    position: relative;
    width: 100%;
}
.nameBg {
    position: absolute;
    bottom: 0;
    left: -60px;
    width: 150px;
    height: 20px;
}
.nameBg::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 150px;
    height: 15px;
    background-color: #C3E0A6;
    z-index: -9;
}
.name {
    font-size: 22pt;
    font-family: "Noto Sans KR";
    font-weight: 800;
    color: #004435;
    margin: 0;
}
.contents {
    font-size: 15pt;
    font-family: "Noto Sans KR";
    font-weight: 600;
}
.imgListBox {
    width: 100%;
    height: 200px;
    margin-top: 20px;
}
.localSlide {
    height: 200px;
    overflow-y: hidden;
}
.localSlide div, .localSlide div div, .localSlide div div .slick-slide,
.localSlide div div .slick-slide div {
    height: 100%;
}
.imgBox {
    display: flex !important;
    width: 280px;
    height: 100%;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
}
.localImg {
    width: 95%;
}
.lastLine {
    position: absolute;
    width: 300px;
    height: 5px;
    bottom: 0;
    right: 0;
}
.lastLine::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 500px;
    height: 2px;
    background-color: #004435;
    z-index: -9;
}

@media (max-width: 900px) {
    .localCard {
        height: fit-content;
    }
    .nameBox {
        width: 90%;
        margin: 10px auto;
    }
    .name {
        font-size: 18pt;
    }
    .nameBg::after {
        height: 15px;
        width: 150px;
    }
    .contentsBox, .imgListBox {
        width: 90%;
        margin: auto;
    }
    .contents {
        font-size: 11pt;
    }
    .lastLine::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 350px;
        height: 2px;
        background-color: #004435;
        z-index: -9;
    }
}

@media (max-width: 500px) {
    .localCard {
        height: fit-content;
    }
    .nameBox {
        width: 85%;
        margin: 10px auto;
    }
    .name {
        font-size: 15pt;
    }
    .nameBg::after {
        height: 10px;
        width: 140px;
    }
    .contentsBox, .imgListBox {
        width: 100%;
        margin: 0;
    }
    .localSlide {
        height: 140px;
    }
    .imgListBox {
        height: 200px;
    }
    .contents {
        font-size: 11pt;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        line-height: 17pt;
    }
}
.articleSection {
    height: fit-content;
    min-height: 100%;
}

.articleContainer {
    position: relative;
    display: flex;
    width: 80%;
    margin: auto;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
}

.titleBox {
    position: relative;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 0;
    width: 75%;
    text-align: center;
}
.titleBg {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 150px;
    height: 20px;
}
.titleBg::after {
    content: '';
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 150px;
    height: 20px;
    background-color: #C3E0A6;
    z-index: -9;
}
.title {
    font-family: "Noto Sans KR";
    font-size: 30pt;
    color: #004435;
    font-weight: 900;
    margin-bottom: 0;
}

.editBtn {
    position: absolute;
    right: 50px;
    top: 120px;
    width: 100px;
    height: 40px;
    font-family: "Noto Sans KR";
    font-size: 14pt;
    font-weight: 600;
    color: #004435;
    border: 3px solid #004435;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
}
.editBtn:hover {
    background-color: #004435;
    color: #fff;
}


.tabMenuBox {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: center;
    margin-top: 30px;
}
.tabMenuList {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
}
.menuItem, .activeMenuItem {
    position: relative;
    list-style: none;
    font-family: "Noto Sans KR";
    font-size: 16pt;
    color: #555555;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0 10px;
    overflow: hidden;
    z-index: 5;
}
.menuItem:hover, .activeMenuItem {
    font-size: 22pt;
    line-height: 40px;
    font-weight: 900;
    color: #004435;
}

.tabContentBox {
    display: flex;
    width: 100%;
    min-height: 500px;
    justify-content: center;
}

.cardList {
    display: flex;
    width: 1080px;
    height: 100%;
    padding: 20px 0;
    flex-wrap: wrap;
}

@media (min-width: 1980px) {
    .titleBox {
        margin-top: 0;
    }
    .title {
        margin: 0;
    }
}

@media (max-width: 1250px) {
    .articleContainer {
        width: 100%;
    }
}

@media (max-width: 720px) {
    .articleTitleBox {
        height: 150px;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        color: #000;
    }
    .articleTitle {
        padding-top: 50px;
    }
    
    .tabMenuBox {
        display: flex;
        width: 100%;
        height: 40px;
        justify-content: center;
    }
    .tabMenuList {
        list-style: none;
        display: flex;
        align-items: center;
        padding: 0;
    }
    .menuItem, .activeMenuItem {
        position: relative;
        list-style: none;
        font-size: 13pt;
        height: 40px;
        line-height: 40px;
        padding: 0 15px;
        cursor: pointer;
        transition: all 0.3s ease;
        margin: 0 5px;
        border-radius: 7px;
        overflow: hidden;
    }
    .menuItem:hover, .activeMenuItem {
        font-size: 18pt;
        line-height: 40px;
        font-weight: 900;
        color: #004435;
    }

    .tabContentBox {
        align-items: flex-start;
    }

    .cardList {
        width: 100%;
    }
}
.pickageCard {
    width: 19%;
    display: flex;
    cursor: pointer;
    margin: 10px 0;
}
.imgBox {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.cardImg {
    width: 100%;
    height: auto;
    transition: all 0.2s ease;
}

.pickageCard:hover .cardImg {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

@media (max-width: 1340px) {
    .pickageCard {
        width: 80%;
    }
}
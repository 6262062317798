.projectCards {
    display: flex;
    width: fit-content;
    margin: 0 auto;
    height: 100%;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 5;
}
.imgBox {
    display: flex;
    width: 250px;
    height: 350px;
    margin: 10px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.cardThumbnail {
    width: 100%;
    transition: all 0.2s ease;
}
.imgBox:hover .cardThumbnail {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}
.spaceSection {
    width: 100%;
}
.contentsList {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 30px;
}

@media (max-width: 900px) {
    .spaceSection {
        width: 100%;
        margin: auto;
    }
    .contentsList {
        width: 100%;
        margin-top: 30px;
    }
}

@media (max-width: 500px) {
    .spaceSection {
        width: 100%;
        margin: 0;
    }
}
.localSection {
    width: 75%;
    margin: auto;
}
.contentsList {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

@media (max-width: 900px) {
    .localSection {
        width: 100%;
        margin: 0;
    }
}

@media (max-width: 500px) {
    .localSection {
        width: 100%;
        margin: 0;
    }
}
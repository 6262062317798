.historySection {
    min-height: 100%;
}
.container {
    margin-top: 80px;
    display: flex;
    position: relative;
    width: 100%;
}
.left {
    width: 23%;
    height: auto;
    background-color: #004435;
}
.title {
    margin-top: 60px;
    width: 60%;
    text-align: end;
    color: #fff;
    font-family: "Noto Sans KR";
    font-weight: 900;
    font-size: 25pt;
}
.right {
    height: 100%;
    margin-left: 30px;
    margin-top: 60px;
    margin-bottom: 30px;
}

.historyItems {
    display: flex;
}
.historyItems p {
    font-family: "Noto Sans KR";
    font-size: 15pt;
    font-weight: 600;
    margin: 10px 0;
}

.dateBox {
    width: 120px;
    margin-right: 30px;
}

@media (max-width: 875px) {
    .left {
        position: absolute;
        top: 0;
        left: 0;
        width: 180px;
        height: 100%;
        padding-right: 20px;
        z-index: -9;
    }
    .title {
        text-align: end;
        width: 100%;
    }
    .right {
        margin-top: 130px;
        margin-left: 70px;
    }
    .dateBox {
        display: flex;
        justify-content: end;
    }
    .date {
        color: #fff;
    }
}

@media (max-width: 730px) {
    .title {
        font-size: 18pt;
    }
    .historyItems p {
        font-size: 12pt;
    }
}

@media (max-width: 600px) {
    .left {
        width: 100%;
    }
    .right {
        margin-left: 20px;
        margin-right: 20px;
    }
    .title {
        text-align: start;
        margin-left: 20px;
    }
    .dateBox {
        margin: 0;
        justify-content: start;
    }
    .contentsBox {
        width: 100%;
    }
    .contents {
        color: #fff;
    }
}
.table {
    width: 100%;
    margin-top: 30px;
    border-spacing: 0;
    table-layout: fixed;
}
.tableHead th {
    height: 30px;
    font-size: 15pt;
    padding-bottom: 15px;
    border-bottom: 3px solid #004435;
}
.idxCategory, .idxEmail {
    width: 200px;
}
.idxName {
    width: 150px;
}

.tableBody tr:first-child:hover {
    cursor: default;
    background-color: #fff !important;
}
.blank {
    height: 20px !important;
    padding: 0 !important;
}
.tableBody tr:hover {
    margin-top: 20px;
    background-color: #eaeaea;
    cursor: pointer;
}
.tableBody tr td {
    padding: 10px 0;
    font-size: 14pt;
    height: 30px;
}
.category, .name, .email {
    text-align: center;
    width: 10%;
}
.content {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 860px) {
    .idxName {
        width: 100px;
    }
    .idxEmail, .idxCategory {
        width: 130px;
    }
    td {
        font-size: 12pt !important;
    }
}

@media (max-width: 600px) {
    .blank {
        display: none;
    }
    .table .tableHead tr th {
        font-size: 12pt;
    }
    .idxName {
        width: 50px;
    }
    .idxEmail, .idxCategory {
        width: 100px;
    }
    td {
        font-size: 9pt !important;
    }
}

@media (max-width: 400px) {
    .table {
        table-layout: fixed;
    }
    .blank {
        display: none;
    }
    .table .tableHead tr th {
        font-size: 9pt;
        padding: 0;
        padding-bottom: 5px;
    }
    .idxCategory, .idxName {
        width: 50px;
    }
    .idxEmail {
        width: 70px;
    }
    .email {
        word-wrap: break-word;
    }
    td {
        font-size: 9pt !important;
    }
}

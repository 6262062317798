.loginSection {
    position: relative;
    width: 100vw;
    height: 100vh;
}

.loginForm {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    height: 400px;
    background-color: white;
    border: 5px solid #004435;
    padding: 10px;
}

.loginTitle {
    text-align: center;
    font-size: 20pt;
    margin: 30px;
}

.emailBox, .pwBox {
    display: flex;
    flex-direction: column;
    margin: 10px 10px;
}

.inputs {
    font-size: 15px;
    border: 1px solid #004435;
    padding: 5px;
}
label {
    font-size: 15px;
    font-weight: bold;
}

.btnBox {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}

.loginBtn {
    cursor: pointer;
    padding: 10px 20px;
    width: 95%;
    font-size: 15px;
    font-weight: bold;
    border-radius: 3px;
    color: #fff;
    border: 3px solid #004435;
    background-color: #004435;
    transition: all 0.3s ease;
}

.loginBtn:hover {
    color: #004435;
    background-color: #fff;
}
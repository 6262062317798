.detailSection {
    min-height: 70vh;
}

.titleBox {
    margin-top: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #004435;
}
.title {
    font-family: "Noto Sans KR";
    font-size: 25pt;
    font-weight: 800;
}

.contentsBox {
    width: 90%;
    display: flex;
    margin: auto;
    padding: 20px 0;
    justify-content: center;
}
.thumbnailBox {
   width: 30%; 
   height: 70vh;
   display: flex;
   justify-content: center;
   align-items: start;
}
.thumbnail {
    width: auto;
    height: 100%;
}
.contents {
    font-family: "Noto Sans KR";
    padding: 20px;
    width: 30%;
    height: 70vh;
}

.contents figure {
    display: flex;
    align-items: center;
    justify-content: center;
}

.imgBox {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 70vh;
}
.flexBox {
    display: block;
}
.projectSlide {
    margin: auto 0;
}

.carouselItem {
    position: relative;
    display: flex !important;
    width: 100%;
    height: 250px;
    text-align: center;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.projectImg {
    height: 100%;
    margin: auto;
}

.btnBox {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnBox button {
    margin: 0 20px;
    font-size: 12pt;
    padding: 5px 15px;
    font-weight: 600;
    cursor: pointer;
}

.modify {
    color: #fff;
    border: 3px solid #004435;
    background-color: #004435;
    transition: all 0.3s ease;
}
.modify:hover {
    color: #004435;
    background-color: #fff;
}

.delete {
    color: #ff5555;
    background-color: #fff;
    border: 3px solid #ff5555;
    transition: all 0.3s ease;
}
.delete:hover {
    color: #fff;
    background-color: #ff5555;
}

@media (max-width: 1560px) {
    .detailSection {
        min-height: 70vh !important;
    }
    .contentsBox {
        flex-wrap: wrap;
    }
    .thumbnailBox {
        width: 45%;
        height: fit-content;
        margin: 20px auto;
    }
    .thumbnail {
        width: 100%;
        height: auto;
    }
    .contents {
        width: 45%;
        height: fit-content;
        margin: 20px auto;
    }
    .imgBox {
        display: flex;
        width: 100%;
        height: fit-content;
        flex-direction: row;
        justify-content: space-around;
    }
    .projectSlide {
        width: 40%;
    }
}

@media (max-width: 960px) {
    .thumbnailBox {
        width: 70%;
        margin: auto;
        height: fit-content;
    }
    .contents {
        width: 70%;
        height: fit-content;
        margin: auto;
        text-align: center;
    }
    .imgBox {
        display: flex;
        width: 100%;
        height: fit-content;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .projectSlide {
        margin-top: 10px;
        width: 100%;
    }
}

@media (max-width: 600px) {
    .titleBox {
        margin-top: 60px;
    }
}
.main {
    height: 100vh;
    overflow-y: hidden;
}
.titleBox {
    position: relative;
    margin: auto;
    margin-top: 60px;
    width: 75%;
    text-align: center;
}
.title {
    font-size: 30pt;
    color: #004435;
    font-weight: 900;
    font-family: "Noto Sans KR";
    margin-bottom: 0;
}
.tabMenuBox {
    display: flex;
    width: 480px;
    height: 50px;
    justify-content: center;
    margin: 20px auto;
    padding-bottom: 10px;
    border-bottom: 3px solid #004435;
}
.tabMenuList {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
}
.menuItem, .activeMenuItem {
    position: relative;
    list-style: none;
    font-size: 16pt;
    color: #555555;
    font-weight: 600;
    font-family: "Noto Sans KR";
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0 10px;
    overflow: hidden;
    z-index: 5;
}
.menuItem:hover, .activeMenuItem {
    font-size: 22pt;
    line-height: 40px;
    font-weight: 900;
    color: #004435;
}

@media (max-width: 1500px) {
    .main {
        height: fit-content;
        overflow-y: scroll;
    }
}
@media (max-width: 500px) {
    .tabMenuBox {
        display: flex;
        width: 100%;
        height: 40px;
        justify-content: center;
    }
    .tabMenuList {
        list-style: none;
        display: flex;
        align-items: center;
        padding: 0;
    }
    .menuItem, .activeMenuItem {
        position: relative;
        list-style: none;
        font-size: 13pt;
        height: 40px;
        line-height: 40px;
        padding: 0 15px;
        cursor: pointer;
        transition: all 0.3s ease;
        margin: 0 5px;
        border-radius: 7px;
        overflow: hidden;
    }
    .menuItem:hover, .activeMenuItem {
        font-size: 18pt;
        line-height: 40px;
        font-weight: 900;
        color: #004435;
    }
}

@media (max-width: 300px) {
    .titleBg::after {
        content: '';
        position: absolute;
        bottom: 5px;
        left: 0;
        width: 120px;
        height: 10px;
        background-color: #C3E0A6;
        z-index: -9;
    }
    .title {
        font-size: 20pt;
        color: #004435;
        font-weight: 800;
        margin-bottom: 0;
    }
    .tabMenuBox {
        padding: 0;
    }
    .menuItem, .activeMenuItem {
        position: relative;
        list-style: none;
        font-size: 9pt;
        height: 30px;
        line-height: 30px;
        padding: 0 15px;
        cursor: pointer;
        transition: all 0.3s ease;
        margin: 0 5px;
        border-radius: 7px;
        overflow: hidden;
    }
    .menuItem:hover, .activeMenuItem {
        font-size: 12pt;
        line-height: 30px;
        font-weight: 900;
        color: #004435;
    }
}